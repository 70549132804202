.projects__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.project__item {
    background: var(--color-bg-variant);
    padding: 1rem;
    border-radius: 2em;
    border: 1px solid transparent;
    transition: var(--transition);
}

.project__item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
}

.project__item-image {
    border-radius: 1rem;
    overflow: hidden;
}

.project__item h3 {
    margin: 1rem 0 1rem;
}

.project__item-cta {
    display: flex;
    align-self: center;
}

/* ===== MEDIA QUERIES (MEDIUM DEVICES)/(TABLETS) ===== */
@media screen and (max-width: 1024px) {
    .projects__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

/* ===== MEDIA QUERIES (SMALL DEVICES)/(PHONES) ===== */
@media screen and (max-width: 600px) {
    .projects__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}